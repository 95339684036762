<template>
  <main>

    <div class="form" v-if="email">
      <br />
      <Form :class="'form_step'+step"
            id="monform"
            :form_id="form_id"
            :server="server"
            @on_error="viewError"
             :hideError="hiderror"
            v-on:results="getResult"
            v-on:step-up="checkStep"
            v-on:step-down="checkStep"
            v-on:form_submit_loading=loading
            :relay="email"
          />
    </div>
    <div class="captcha">
      This site is protected by reCAPTCHA and the Google<br />
      <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and
      <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
    </div>
  </main>
</template>
<script>
var consts = require('../config');
import axios from "axios";

export default {
  name: "HOME",
  data: function () {
    return {
      hiderror: true,
      step: 0,
      email: undefined,
      form_id: consts.FORM_ID,
      server: consts.SERVER_URL,
    };
  },

  async mounted () {

      if(localStorage.email) {
        this.email = { email : localStorage.email }
      } else {
         this.$swal({
            type: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#C94330',
            text: 'Une erreur est survenue'
            }).then(function() {
              document.location.href="/";
           })    
      }
  },  
  methods: {
   viewError(err) {
      console.log(err)
        if(err.indexOf('ip') > -1) {
            this.$swal({
            confirmButtonText: 'OK',
            confirmButtonColor: '#C94330',
            title: 'Désolé, mais vous avez déjà participé avec cette ip.',
            text: ""
          }).then(function () {
              
            window.location.href = "/";
          });
        } else {
          if(err[0] == 'enseigne is required') err[0] = 'Enseigne requise'
          this.$swal({
            type: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#C94330',
            text: err[0]
            }).then(function() {
              document.location.href="/";
           })   
        }
    },   
    loading(value) {
      if (value === true) {
        this.$swal({
          title: 'Envoi en cours',
          allowOutsideClick: false,
          html: 'Merci de bien vouloir patienter ...',
          timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swal.showLoading()
          }
        })
      }
    },
    checkStep() {
       this.step = this.step++
    },
    getResult(result) {
      if(result._id) {
         this.$swal.close()
       this.$router.replace("/status/"+result._id+'/'+result.uid);
      } else {
       alert('une erreur est survenue pendant le processus')
      }
    },
  }
} 
</script>